.product-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    padding: 5px 0 5px;

    .selected {
        border: 1px solid #979797;
    }
}

.product-section-images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
}

.product-section-thumbnail {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    min-height: 66px;
    cursor: pointer;

    &:hover {
        border: 1px solid #979797;
    }
}

.product-section-image-small {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  height: 220px;

    img {
        opacity: 0;
        transition: opacity .10s ease-in-out;
        padding: 30px;
    }

    img.active {
        opacity: 1;
    }
}

.product-section-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    padding: 30px;
    text-align: center;
    height: 400px;

    img {
        opacity: 0;
        transition: opacity .10s ease-in-out;
    }

    img.active {
        opacity: 1;
    }
}

.product-section-information {

    p {
        margin-bottom: 16px;
    }

}

.product-section-title {
    margin-bottom: 0;
}

.product-section-subtitle {
    font-size: 20px;
    font-weight: bold;
    color: $text-color-light;
}

.product-section-price {
    font-size: 38px;
    color: $text-color;
    margin-bottom: 16px;
}


.search-results-container {
  margin: 10px auto;

  a {
    color: darkblue;

    &:hover {
      text-decoration: underline;
    }
  }
}

.search-results-count {
  margin-bottom: 20px;
}

.search-section {
  display: grid;
  grid-template-columns: 1fr;
  margin: 20px auto 20px;

  .sidebar li.active {
    font-weight: 500;
  }

  .products-searched {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 80px $gutter;

    .product-price {
      color: $text-color-light;
    }
  }
}


.products-searched-header {
  display: flex;
  justify-content: space-between;
}

.product-searched-section-image-small {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #979797;
  padding: 30px;
  text-align: center;
  height: 200px;

  img {
    opacity: 1;
    transition: opacity .10s ease-in-out;
    max-height: 100%;
  }

  img.active {
    opacity: 1;
  }
}

.product-searched-section-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #979797;
  padding: 30px;
  text-align: center;
  height: 400px;

  img {
    opacity: 0;
    transition: opacity .10s ease-in-out;
    max-height: 100%;
  }

  img.active {
    opacity: 1;
  }
}

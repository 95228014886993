
.collapsed {
  display: block;
  /* same width as sidebar */
  margin-left: -17.125rem;
}

.sidebar {
    h3 {
        font-weight: bold;
        margin-bottom: 16px;
    }

    ul {
        line-height: 2;
        margin-bottom: 20px;
    }
}

.sidebar-nav-1 {
  display: block;
  width: 17.125rem;
  position: fixed;
  transition: margin 0.3s ease;
  height: 100%;
  background: whitesmoke;
  z-index: 1;
  margin-top: 40px;

  h3 {
    font-weight: bold;
    margin-bottom: 16px;
  }


  ul {
    line-height: 2;
    margin-bottom: 20px;
    border-top: 0;
    padding: .5rem .75rem;
    border-bottom: 1px solid #eaeaea;
    height: 100%;
  }

  li {
    --vh: 11.18px;
    font-family: Barlow, sans-serif;
    font-weight: 500;
    color: #000;
    -webkit-font-smoothing: antialiased;
    pointer-events: auto;
    list-style-position: outside;
    line-height: 1.3;
    box-sizing: inherit;
    font-size: inherit;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    border-bottom: 1px solid #fff;
    background: #e5eef8;

    img {
      --vh: 11.18px;
      font-family: Barlow, sans-serif;
      -webkit-font-smoothing: antialiased;
      pointer-events: auto;
      list-style-position: outside;
      list-style-type: none;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25;
      color: #000;
      box-sizing: inherit;
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      border: 0;
      height: 25px;
      width: 25px;
    }

    a {
      --vh: 11.18px;
      font-family: Barlow, sans-serif;
      -webkit-font-smoothing: antialiased;
      pointer-events: auto;
      list-style-position: outside;
      list-style-type: none;
      box-sizing: inherit;
      background-color: rgba(0, 0, 0, 0);
      text-decoration: none;
      cursor: pointer;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      flex: 1;
      padding: 12px 15px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25;
      color: #000;
    }
  }

  .item {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
  }


}

.container-menu {
  padding-left: 17.125rem;
  transition: padding 0.3s ease;

  .featured-section {


  }
}

.container-menu-toggle {
  padding-left: 0;
  transition: padding 0.3s ease;
}

.icon {
  .icon-hamburger {
    --vh: 9.97px;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    font-size: 100%;
    text-transform: none;
    line-height: 1;
    font-family: inherit;
    cursor: pointer;
    box-sizing: inherit;
    position: relative;
    vertical-align: middle;
    width: 1.875rem;
    height: 1.875rem;
    display: block;
    color: #fff;
    fill: #fff;
  }
}
#hamburger {
  --vh: 9.97px;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  font-size: 100%;
  text-transform: none;
  line-height: 1;
  font-family: inherit;
  cursor: pointer;
  box-sizing: inherit;
  position: relative;
  vertical-align: middle;
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  color: #fff;
  fill: #fff;
}

.shrink {
  --vh: 9.97px;
  font-family: Barlow,sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-size: .875rem;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: auto;
}

.buttonss {
  --vh: 9.97px;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  font-size: 100%;
  margin: 0;
  overflow: visible;
  text-transform: none;
  appearance: none;
  border-radius: 0;
  line-height: 1;
  font-family: inherit;
  border: 0;
  cursor: pointer;
  align-self: center;
  margin-left: 0;
  padding-left: 1.1875rem;
  padding-right: 1.1875rem;
  outline: 0;
  padding-top: .9375rem;
  padding-bottom: .9375rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: rgba(0,0,0,.3);
}

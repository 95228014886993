.button {
  border: 1px solid $text-color;
  padding: 12px 40px;

  &:hover {
    color: $white;
    background: $text-color;
  }
}

.button-white {
  border: 1px solid $white;
  color: $white !important;

  &:hover {
    color: $text-color !important;
    background: $white;
  }
}

.button-primary {
  background: $primary;
  color: white !important;
  padding: 12px 40px;

  &:hover {
    background: darken($primary, 7%);
  }

  &:disabled {
    background: lighten($primary, 10%);
    cursor: not-allowed;
  }
}

.button-secondary {
  background: $secondary;
  color: white !important;
  padding: 12px 40px;

  &:hover {
    background: darken($secondary, 7%);
  }

  &:disabled {
    background: lighten($secondary, 10%);
    cursor: not-allowed;
  }
}

.button-plain {
  border: 1px solid $text-color !important;
  background: transparent;
}

.button-container {
  margin: 80px 0;

  .button-container-catalogs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px $gutter;
  }
}

.search-form {
    position: relative;
}

.search-icon {
    color: gray;
    position: absolute;
    top: 9px;
    left: 12px;
}

.search-box {
    padding: 9px 12px 8px 34px;
    width: 50%;
    font-size: 15px;
    border: 3px #f6f6f6 solid !important;
    border-radius: 2em !important;
    box-shadow: none !important;
    mso-border-shadow: no;
    mso-page-border-surround-header: no;
}

.flex-nav {
    display: flex;
    padding-top: 8px;
}

.search-box-small {
    padding: 10px 12px 10px 34px;
    width: 100%;
    max-width: 100%;
    font-weight: 500;
    line-height: 1.5;
    font-size: .875rem;
    border-radius: 2em;
    box-sizing: inherit;
    border: $blue-background;
}

.search-form-small {
    position: relative;
    z-index: 10;
    font-size: 1rem;
    font-weight: 500;
    background: none;
    margin-left: 18%;
    width: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.breadcrumbs {
    background: $light-gray-background;
    alignment-baseline: baseline;
    left: 0;
    right: 0;
    z-index: 9;
    top: 124px;

    .breadcrumb-separator {
        font-size: 14px;
        color: lighten($text-color, 20%);
    }

    .breadcrumbs-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.featured-section {

    .products {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;

        .product-price {
            color: $text-color-light !important;
            font-weight: 500;
            color: #000;
            list-style-position: outside;
            line-height: 1.3;
            font-size: inherit;
            list-style-type: none;
            -webkit-box-direction: normal;
            box-sizing: inherit;
        }
    }

    .product {
        padding: 10px;
    }
}

.blog-section {
    background: $light-gray-background;
    border-top: 1px solid $separator-color;
    padding: 50px 0;
    grid-area: blog-section;

    .blog-posts {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: $gutter;
        margin: 60px 0 60px;
        grid-template-areas:
        "blog1 blog2 blog3";

        #blog1 {
            grid-area: blog1;
        }

        #blog2 {
            grid-area: blog2;
        }

        #blog3 {
            grid-area: blog3;
        }
    }

}
